/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Footer from 'Component/Footer';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import { CmsPageContainerProps } from 'Route/CmsPage/CmsPage.type';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { ReactElement } from 'Type/Common.type';
import BrowserDatabase from 'Util/BrowserDatabase';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { RootState } from 'Util/Store/Store.type';

import {
    HomePageContainerMapDispatchProps,
    HomePageContainerMapStateProps,
    HomePageContainerProps,
    HomePageContainerPropsKeys,
} from './HomePage.type';

import './HomePage.style';

/** @namespace TrouperPwa/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): HomePageContainerMapStateProps => ({
    identifier: state.ConfigReducer.cms_home_page,
});

/** @namespace TrouperPwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): HomePageContainerMapDispatchProps => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
});

export const InstallPrompt = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "install-prompt" */
    'Component/InstallPrompt'
));

/** @namespace TrouperPwa/Route/HomePage/Container */
export class HomePageContainer extends PureComponent<HomePageContainerProps> {
    state = {
        isInstallPromptAvailable: window.isInstallPromptAvailable,
    };

    videoTriggered = false;

    componentDidMount(): void {
        const {
            changeHeaderState,
        } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });
        this.initializeVideoBlockTracking();
    }

    componentDidUpdate() {
        this.initializeVideoBlockTracking();
    }

    containerProps(): Pick<CmsPageContainerProps, HomePageContainerPropsKeys> {
        const {
            changeHeaderState,
            currentUrl,
            match,
            identifier,
        } = this.props;

        return {
            changeHeaderState,
            currentUrl,
            match,
            pageIdentifiers: identifier,
        };
    }

    isFullyScrolledIntoView(element:any) {
        const rect = element.getBoundingClientRect();

        return (
            rect.top >= 0
            && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    debounce(func:any, wait:any) {
        let timeout:any;

        return function executedFunction(...args:any) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    initializeVideoBlockTracking = () => {
        const timeInterval = 100;
        window.addEventListener('scroll', this.debounce(() => {
            const videoBlock = document.querySelector('.home-video-section');

            if (videoBlock){
                const iframe = document.getElementById('youtube-video');
                let src = iframe?.getAttribute('src') ?? '';

                if (this.isFullyScrolledIntoView(videoBlock) && !this.videoTriggered) {
                    // Start autoplay when the block is visible
                    if (!src.includes('autoplay=1')) {
                        src += '&autoplay=1'; // Add autoplay and mute parameters
                        iframe?.setAttribute('src', src);
                    }
                    this.videoTriggered = true;
                } else if (!this.isFullyScrolledIntoView(videoBlock) && this.videoTriggered) {
                    // Stop autoplay when the block is no longer visible
                    const srcWithoutAutoplayAndMute = src.replace('&autoplay=1', '');
                    iframe?.setAttribute('src', srcWithoutAutoplayAndMute); // Remove autoplay and mute

                    this.videoTriggered = false;
                }
            }
        }, timeInterval));
    };

    renderInstallPrompt() {
        const { isInstallPromptAvailable } = this.state;

        if (!isInstallPromptAvailable) {
            const beforeInstallPromptHandler = (event: Event) => {
                event.preventDefault();
                BrowserDatabase.deleteItem('app_installed');
                window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
            };

            window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

            return null;
        }

        return (
            <Suspense fallback={ (
                <div block="HomePage" elem="InstallPromptFallbackWrapper">
                    <div block="HomePage" elem="InstallPromptFallback" />
                </div>
            ) }
            >
                <InstallPrompt />
            </Suspense>
        );
    }

    render(): ReactElement {
        const { isInstallPromptAvailable } = this.state;

        return (
            <div block="HomePage" mods={ { mobile: !!isInstallPromptAvailable } }>
                { this.renderInstallPrompt() }
                <CmsPage { ...this.containerProps() } />
                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
