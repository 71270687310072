import React from 'react';
import Slider from 'react-slick'; // Slick slider component

import { SlickSliderComponentProps } from './SlickSlider.type';

// Importing slick CSS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/** @namespace TrouperPwa/Component/SlickSlider/Component */
export class SlickSliderComponent extends React.Component<SlickSliderComponentProps> {
    private sliderRef: React.RefObject<Slider> = React.createRef();

    componentDidMount = () => {
    // Manually initialize the slick slider once the component is mounted
        this.initializeSlickSlider();
    };

    componentDidUpdate = (prevProps: any) => {
        const { categoryItems } = this.props;

        if (prevProps.categoryItems !== categoryItems) {
            this.initializeSlickSlider();
        }
    };

    // Function to initialize Slick slider programmatically
    initializeSlickSlider = () => {
        if (this.sliderRef.current) {
            const slider = this.sliderRef.current; // Accessing the HTML content
            // Manually trigger the Slick slider if needed
            slider.slickGoTo(0); // For example, go to the first slide
            // You can add other initialization logic or method calls from the slick API if needed
        }
    };

    render() {
        const { categoryItems } = this.props;

        return (

        <Slider
          ref={ this.sliderRef }
          { ...this.props }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        >
          { categoryItems && categoryItems.map((item: Element) => (
            <div
              dangerouslySetInnerHTML={ { __html: item.innerHTML } }// eslint-disable-line @typescript-eslint/naming-convention
            />
          )) }
        </Slider>
        );
    }
}

export default SlickSliderComponent;
