/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import {
    ChangeNavigationStateAction,
    GoToPreviousNavigationStateAction,
    MenuNavigationStateAction,
    NavigationActionType,
    NavigationState,
    NavigationType,
} from './Navigation.type';

/** @namespace TrouperPwa/Store/Navigation/Action/changeNavigationState */
export const changeNavigationState = (
    navigationType: NavigationType,
    navigationState: NavigationState,
): ChangeNavigationStateAction => ({
    type: NavigationActionType.CHANGE_NAVIGATION_STATE,
    navigationType,
    navigationState,
});

/** @namespace TrouperPwa/Store/Navigation/Action/goToPreviousNavigationState */
export const goToPreviousNavigationState = (
    navigationType: NavigationType,
): GoToPreviousNavigationStateAction => ({
    type: NavigationActionType.GOTO_PREVIOUS_NAVIGATION_STATE,
    navigationType,
});

/** @namespace TrouperPwa/Store/Navigation/Action/menuNavigationState */
export const menuNavigationState = (
    navigationType: NavigationType,
    navigationState: NavigationState,
): MenuNavigationStateAction => ({
    type: NavigationActionType.MENU_NAVIGATION_STATE,
    navigationType,
    navigationState,
});
